<template>
  <el-card>
      <div slot="header">Configuration</div>
      <div>
        <div class="item">
          E-Soccer automatically create teams：
          <el-select v-model="config.electronicSoccerAutoCreateTeamDatasource" placeholder="Select" @change="save('electronicSoccerAutoCreateTeamDatasource')" clearable="" size="mini" >
           <el-option v-for="item in sourceList" :key="item.value" :value="item.value" :label="item.label"></el-option>
           <el-option label="Off" :value="0"></el-option>
          </el-select>
        </div>
        <br>
        <div class="item">
          E-Basketball automatically create teams：
          <el-select v-model="config.electronicBasketballAutoCreateTeamDatasource" placeholder="Select" @change="save('electronicBasketballAutoCreateTeamDatasource')"  clearable="" size="mini">
            <el-option v-for="item in sourceList" :key="item.value" :value="item.value" :label="item.label"></el-option>
            <el-option label="Off" :value="0"></el-option>
          </el-select>
        </div>
      </div>
  </el-card>
</template>

<script>
import { saveConfiguration, getConfiguration } from '@/service'
import { originData } from  '@/utils/constants'
export default {
  data()  {
    return {
      sourceList: originData,
      config: {}
    }
  },
  created() {
    this.getConfiguration()
  },
  methods: {
    getConfiguration()  {
      getConfiguration().then(res =>  {
        if(res.code === 0) {
          this.config = res.data
        }
      })
    },
    save(key) {
      saveConfiguration({[key]: this.config[key]}).then(res => {
        if(res.code === 0) {
          this.getConfiguration()
          this.$message.success('Success')
        }
      })
    }
  }
}
</script>